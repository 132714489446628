<template>
  <main className="py-5 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 min-h-screen">
    <PageHeader title="Wijzig klant" />

    <div class="mt-10 sm:mt-0" v-if="!loading">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">Let op</h3>
            <p class="mt-1 text-sm text-gray-600">
              Dit zijn alleen de klant logo's op de publieke website en dienen altijd in het zelfde formaat geupload te worden. 800x600 pixels. Deze functie staat los van de CV tool.
            </p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          <form @submit.prevent="handleSave" method="POST">
            <div class="shadow overflow-hidden sm:rounded-md">
              <div class="px-4 py-5 bg-white sm:p-6">
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-6 sm:col-span-6">
                    <label for="title" class="block text-sm font-medium text-gray-700">Client name</label>
                    <input type="text" name="title" id="title" autocomplete="title" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" required v-model="data.title">
                  </div>
                </div>
              </div>
              
              <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500" @click="saveItem">
                  Save
                </button>
              </div>
            </div>
          </form>

          <button type="button" class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500" @click="destroy">
            Delete
          </button>
        </div>
      </div>
    </div>

    <div class="hidden sm:block" aria-hidden="true">
      <div class="py-5">
        <div class="border-t border-gray-200"></div>
      </div>
    </div>

    <div v-if="data">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">Slide image</h3>
            <p class="mt-1 text-sm text-gray-600">
              Upload a high resolution image. The image will be scaled automatically.
            </p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          
            <div class="shadow sm:rounded-md sm:overflow-hidden">
              <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
                
                <div>
                  <Uploader model="Client" :model_id="data.id" @update-add="myMethod" />  
                  <PhotoList model="Client" :photos="data.Photos" :id="data.id" @update-delete="myMethod" />
                </div>
              </div>
              
            </div>
          
        </div>
      </div>

    </div>

  </main>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute } from 'vue-router'
import ClientService from '../../../services/admin/clients/service';
import PhotoList from '../../../components/admin/generic/PhotoList';
import Uploader from '../../../components/admin/generic/Uploader';

export default {
  name: 'clientLogoEdit',
  components: {
    Uploader,
    PhotoList
  },
  setup() {
    const data = ref(null);
    const loading = ref(true);
    const error = ref(null);
    const route = useRoute()
    const id = route.params.id;

    function fetchData() {
      loading.value = true;
      // I prefer to use fetch
      // you can use use axios as an alternative
      return ClientService.get(id).then(res => {
          // a non-200 response code
          if (res.status !== 200) {
            // create error instance with HTTP status text
            const error = new Error(res.statusText);
            error.json = res.json();
            throw error;
          }

          return res.data;
        })
        .then(json => {
          // set the response data
          data.value = json;
        })
        .catch(err => {
          error.value = err;
          // In case a custom JSON error response was provided
          if (err.json) {
            return err.json.then(json => {
              // set the JSON response message
              error.value.message = json.message;
            });
          }
        })
        .then(() => {
          loading.value = false;
        });
    }
    onMounted(() => {
      fetchData();
    });

    return {
      data,
      loading,
      error
    };
  },
  methods: {
    async saveItem() {
      this.loading = true;
      ClientService.save(this.data);
      this.$router.push(`/cv/clients`);
      this.loading = false;
    },
    async destroy() {
      if(confirm('are you sure?')) {
        this.loading = true;
        await ClientService.delete(this.data.id);
        this.$router.push(`/cv/clients/`);
        this.loading = false;
      }
    },
    async myMethod() {
      console.log("Refreshing attachments");
      const data = await ClientService.get(this.$route.params.id); 
      this.data = data.data;
    }
  }
}
</script>